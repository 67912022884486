@import '~sweetalert2/dist/sweetalert2.css';
@import "~normalize.css/normalize.css";
@import '~video.js/dist/video-js.css';
@import "fonts";
@import "material-config";
@import "vars";


$primary: #1F82F7;
$secondary: #333F72;
$max-width: 1400px;
$container-max-widths: (
  sm: $max-width - 3,
  md: $max-width - 2,
  lg: $max-width - 1,
  xl: $max-width
  );
@import "~bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  width: 100%;
  min-height: $min-page-height;
  min-width: $min-page-width;
}

body {
  margin: 0;
  font-family: 'Lato', 'Open Sans', Roboto, "Helvetica Neue", sans-serif;
  background-color: $ori-bg;
}

.table-wrapper {
  height: 100%;
  background: $ori-table-wrapper-background;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: $ori-dropdown-item-hover-background;
  color: $ori-dropdown-item-hover-color;
}

.dropdown-menu {
  background-color: $ori-dropdown-background;
  color: $ori-dropdown-color;
  border-color: $ori-dropdown-border;
}
.swal2-confirm.swal2-styled {
  border-left-color: $ori-swal-border-color !important;
  border-right-color: $ori-swal-border-color !important;
  background-color: $ori-swal-background !important;
}

.mat-raised-button.mat-primary:hover {
  background-color: $ori-accent;
}

button:focus {
  outline: none !important;
}

@include angular-material-theme($ori-theme-light);
