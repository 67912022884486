/*common*/

$ori-primary: #365d9e;
$ori-primary-dark: #243f6b;
$ori-accent: #1F82F7;
$ori-gray: #666666;
$ori-light-gray: #A2AFB2;
$ori-bg:#ecf2fa;

$max-content-width: 1400px;
$min-page-width: 1250px;
$min-page-height: 750px;

$footer-height: 64px;

$ori: (
  50: #e7ecf3,
  100: #c3cee2,
  200: #9baecf,
  300: #728ebb,
  400: #5475ad,
  500: $ori-primary,
  600: #305596,
  700: #294b8c,
  800: #224182,
  900: #163070,
  A100: #a8beff,
  A200: #7599ff,
  A400: #4273ff,
  A700: $ori-accent,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/*header*/
$ori-header-background: $ori-primary;
$header-height: 64px;
$ori-header-title-color: #fff;
$ori-menu-button-color: #fff;
$ori-menu-button-color-hover: rgb(223, 221, 221);
$ori-header-user-color: #fff;
$ori-header-user-position-color: #d1d3dd;
$ori-header-dropdown-icon: #d1d3dd;

/*logo*/
$ori-header-logo-path: url(/assets/ori_logo_white.png);
$ori-header-logo-width: 10rem;
$ori-header-logo-height: 3rem;

/*main-menu*/
$ori-main-menu-item-background: $ori-primary;
$ori-main-menu-item-hover-background: $ori-accent;
$ori-main-menu-item-title-color: #fff;
$ori-main-menu-item-icon-color: #fff;

/*sidebar*/
$ori-sidebar-active: $ori-accent;
$ori-sidebar-dark: $ori-primary-dark;
$ori-sidebar-color: #fff;
$ori-sidebar-active-color: #fff;
$ori-sidebar-toggle-default: #fff;
$ori-sidebar-toggle-active: $ori-accent;

/*violations*/ 

$ori-filter-background: $ori-primary;
$ori-filter-border: $ori-primary;
$ori-filter-color: #fff;
$ori-filter-hover: $ori-accent;
$ori-filter-hover-border: $ori-accent;
$ori-filter-hover-color: #fff;
$ori-filter-active: $ori-accent;
$ori-filter-active-border: $ori-accent;
$ori-filter-active-color: #fff;

$ori-card-background: #fff;
$ori-card-description-color: #8E92A0;
$ori-card-value-color: #000;
$ori-card-status-color: $ori-accent;

$ori-violation-table-header-color: #8E92A0;
$ori-violation-table-header-color-hover: #000;
$ori-violation-table-header-color-active:  #000;
$ori-violation-table-row-background: #fff;
$ori-violation-table-row-hover-background: #F7FAFD;
$ori-violation-table-row-color: #000;
$ori-violation-table-row-color-highlited: $ori-accent;
$ori-violation-table-details-color: $ori-accent;

$ori-pagination-background: #000;
$ori-pagination-disabled-color: #6c757d;
$ori-pagination-disabled-background: #fff;
$ori-pagination-disabled-border: #dee2e6;
$ori-pagination-background: #fff;
$ori-pagination-border: #dee2e6;
$ori-pagination-color: $ori-accent;
$ori-pagination-hover-background: #e9ecef;
$ori-pagination-hover-color: $ori-primary;
$ori-pagination-hover-border: #dee2e6;

/*violation-dialog*/
$ori-violation-dialog-overlay-icon-background: $ori-primary;
$ori-violation-dialog-overlay-icon-color: #fff;
$ori-violation-dialog-overlay-text-color: #fff;
$ori-violation-dialog-background: #fff;

/*swal*/
$ori-swal-border-color: #000;
$ori-swal-background: #000;

/*lookup*/
$ori-lookup-color: $ori-primary;
$ori-lookup-border: $ori-primary;
$ori-lookup-arrow: $ori-primary;
$ori-lookup-select: $ori-primary;

/*tables*/

$ori-table-header-bg: #365d9e12;
$ori-table-header-color: #365d9e;
$ori-table-row-hover: #e3e9f263;
$ori-table-edit-icon: $ori-gray;
$ori-table-edit-icon-hover: $ori-accent;
$ori-table-remove-icon: $ori-gray;
$ori-table-remove-icon-hover: #e42113cc;
$ori-table-cam-icon: $ori-gray;
$ori-table-cam-icon-hover: $ori-accent;
$ori-table-wrapper-background: #fff;
$ori-table-service-header-background: #fff;
$ori-table-add-button-color: $ori-gray;
$ori-table-add-button-background: #fff;
$ori-table-add-button-hover-background: $ori-accent;
$ori-table-add-button-hover-color: #fff;

/*dropdowns*/
$ori-dropdown-item-hover-background: #f8f9fa;
$ori-dropdown-item-hover-color: #16181b;
$ori-dropdown-background: #fff;
$ori-dropdown-color: #212529;
$ori-dropdown-border: rgba(0, 0, 0, 0.15);

/*login page*/
$ori-auth-page-background: url("../../../assets/index-images/login-bg-2k.jpg") center no-repeat;
$ori-auth-button-color: #fff;
$ori-auth-input-background: #fff;
$ori-auth-input-border: #fff;
$ori-auth-button-color: #fff;
$ori-auth-button-background:#3E7DEA;
$ori-auth-button-hover-background: darken($ori-auth-button-background, 10%);
$ori-auth-button-hover-color: #fff;